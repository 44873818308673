import inx from 'inx';

export default (params) => {
    const { promoID, theme } = params;
    const modalClassName = 'promo-modal';
    const className = [
        modalClassName,
        `${modalClassName}_theme-${theme}`,
        params.isXSDevice ? '' : `${modalClassName}_center-vertical`
    ].join(' ');

    // Template based on bootstrap 5 modal
    return `
        <div class="modal ${className} fade" id="${promoID}" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-transparent" style="border:0;padding:0 !important">
                        <button 
                            class="btn-close" 
                            data-bs-action="close" 
                            data-action="close" 
                            title="${inx.i18n.blockedAds.close}" 
                            type="button"
                            style="top: -10px; right: -25px;"
                        >
                        </button>
                    </div>
                    <div class="modal-body" style="margin-bottom:5px; padding-bottom:14px !important;">
                        <h3 style="border-bottom:1px solid #e5e5e5; font-size:28px; font-weight:bold; margin:0 -10px 14px -10px; padding-bottom:14px">${inx.i18n.blockedAds.body.title}</h3>
                        <ul class="text-start bg-transparent" style="margin:0 0 14px 0;padding-left: 22px;">
                          <li>${inx.i18n.blockedAds.body.bullets[0]}</li>
                          <li>${inx.i18n.blockedAds.body.bullets[1]}</li>
                        </ul>
                        <div style="display: flex; flex-direction: column;">
                            <a href="${inx.i18n.blockedAds.allow_url}" class="modal-btn modal-btn_primary fs-6" target="_blank">
                                ${inx.i18n.blockedAds.allow_txt}
                            </a>
                            <a href="${inx.i18n.blockedAds.try_url}" class="modal-btn modal-btn_default fs-6" target="_blank">
                                ${inx.i18n.blockedAds.try_txt}
                            </a>
                        </div>
                    </div>
                    <div class="modal-footer text-left" style="font-size:14px; padding: 0 10px;">
                        <p>
                            ${inx.i18n.blockedAds.report}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    `;
};
