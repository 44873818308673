import transparentPixelSrc from 'src/img/public/spacer.gif';

const tpl = new Map();

tpl.set('item', (args) => {
    const { aHref, imgId, data, imgSrc } = args;
    const { email, name } = data;

    return `
        <li class="account">
            <a
                class="btn btn-brand-dark w-100 text-start px-0 account__link"
                href="${aHref}"
                title="${name}"
            >
                <span class="d-inline-flex flex-grow-1 align-items-center w-100">
                    <span class="account__avatar-wrap">
                        <img
                            alt="Avatar"
                            class="rounded-circle"
                            data-initials="true"
                            data-src="${imgSrc}"
                            id="${imgId}"
                            src="${transparentPixelSrc}"
                        />
                    </span>
                    <span class="d-inline-block text-truncate flex-fill pe-2" title="${email}">${email}</span>
                    <i class="indicator ps-2" data-user="${email}"></i>
                </span>
            </a>
        </li>
    `;
});

tpl.set('itemsList', (items) => `
    <ul class="linked-accounts-list">
        ${items}
    </ul>
`);

tpl.set('addAcount', (aHref, i18n) => `
    <div id="add-account" class="account">
        <a
            class="btn btn-link link-opacity-50-hover ms-1 ps-2 py-2 px-0 w-100 text-start"
            href="${aHref}"
        >
            <i class="ico ipi-plus me-1"></i>
            ${i18n}
        </a>
    </div>
`);

export default tpl;
